const tabMixins = {
    methods: {
        changeTab(sub){
            this.$router.push({path: sub.path})
        },
    },
    computed: {
        getSubActive(){
            return sub => {
                const currRoutes = this.$route.path.split('/')
                const currRoute = currRoutes[currRoutes.length - 1]
                const subRoutes = sub.path.split('/')
                const subRoute = subRoutes[subRoutes.length - 1]
                return {on : currRoute == subRoute}
            }
        }
    }
}

export default tabMixins