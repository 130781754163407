<template>
    <div class="wrap">
        <div class="main_title">Personnel</div>
        <div class="tabs flex">
            <div @click="changeTab(sub)" :class="['tab_label', getSubActive(sub)]" v-for="(sub, idx) in subMenuList" :key="idx">{{sub.name}}</div>
        </div>
        <router-view />
    </div>
</template>
<script>
import tabMixins from '@/mixins/tabMixins'
export default {
    name: 'Personnel',
    mixins: [tabMixins],
    data(){
        return {
            subMenuList: [
                {
                    name: 'Department',
                    path: '/manage/admin/personnel/department'
                },
                {
                    name: 'Employee',
                    path: '/manage/admin/personnel/employee'
                }
            ]
        }
    },
}
</script>
<style lang="stylus" scoped>
</style>